import axios from 'axios';

const state = {
    order: "",
};

const getters = {

};

const actions = {
    order_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/order', { params: data })
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/order', data)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/order/' + data.id)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/order/' + data.id, data)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('cs-management/order/' + data.id)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_change_status({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/order/status/' + data.id, data)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_send_follow_up({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/order/follow-up/' + data.id, data)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_message({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/order-message')
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    order_message_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/order-message', data)
            .then(response => {
                commit("PUSH_ORDER", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_ORDER(state, data){
        state.order = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
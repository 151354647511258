<template>
    
    <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
            <div class="navbar-header">
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
                    <i class="ti-menu ti-close"></i>
                </a>
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <a class="navbar-brand" href="index.html">
                    <!-- Logo icon -->
                    <b class="logo-icon">
                        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                        <!-- Dark Logo icon -->
                        <img src="../../assets/img/logo-icon.png" alt="homepage" class="dark-logo" style="width:40px" />
                        <!-- Light Logo icon -->
                        <img src="../../assets/img/logo-icon.png" alt="homepage" class="light-logo" style="width:40px" />
                    </b>
                    <!--End Logo icon -->
                    <!-- Logo text -->
                    <span class="logo-text">
                        <h3 class="mb-0">Whacenter</h3>
                        <!-- dark Logo text -->
                        <!-- <img src="../../assets/images/logo-text.png" alt="homepage" class="dark-logo" /> -->
                        <!-- Light Logo text -->
                        <!-- <img src="../../assets/images/logo-light-text.png" class="light-logo" alt="homepage" /> -->
                    </span>
                </a>
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Toggle which is visible on mobile only -->
                <!-- ============================================================== -->
                <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="ti-more"></i>
                </a>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse" id="navbarSupportedContent">
                <!-- ============================================================== -->
                <!-- toggle and nav items -->
                <!-- ============================================================== -->
                <ul class="navbar-nav float-left mr-auto">
                    <li class="nav-item d-none d-md-block">
                        <a class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)" data-sidebartype="mini-sidebar">
                            <i class="sl-icon-menu font-20"></i>
                        </a>
                    </li>
                </ul>
                <!-- ============================================================== -->
                <!-- Right side toggle and nav items -->
                <!-- ============================================================== -->
                <ul class="navbar-nav float-right">
                    <!-- ============================================================== -->
                    <!-- User profile and search -->
                    <!-- ============================================================== -->
                    <li class="nav-item dropdown" :class="isShowProfileMenu ? 'show' : ''">
                        <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="#" data-toggle="dropdown" aria-haspopup="true" :aria-expanded="isShowProfileMenu" @click="toogleProfileMenu">
                            <img src="../../assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY" :class="isShowProfileMenu ? 'show' : ''">
                            <span class="with-arrow">
                                <span class="bg-primary"></span>
                            </span>
                            <div class="d-flex no-block align-items-center p-15 bg-primary text-white mb-2" style="padding: 10px">
                                <!-- <div class="">
                                    <img src="../../assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
                                </div> -->
                                <div>
                                    <!-- <h4 class="m-b-0">{{ user?.name || '-' }}</h4> -->
                                    <p class=" mb-0">{{ user?.email || '-' }}</p>
                                </div>
                            </div>
                            <router-link to="/profile" class="dropdown-item"><i class="ti-user m-r-5 m-l-5"></i> My Profile</router-link>
                            
                            <!-- <div class="dropdown-divider"></div> -->
                            <a class="dropdown-item" @click="logout"><i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                            
                        </div>
                    </li>
                    <!-- ============================================================== -->
                    <!-- User profile and search -->
                    <!-- ============================================================== -->
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    components: { 
    },
    data(){
        return{
          user: this.$store.state.auth.user,
          isShowProfileMenu: false,
        }
    },
    mounted () {

    },
    watch: {
        '$route'() {
            this.isShowProfileMenu = false
        }
    },
    methods:{
        toogleProfileMenu(){
            this.isShowProfileMenu = !this.isShowProfileMenu
        },
        async toggleSidebar(){
            this.$root.$emit("toggle_sidebar", true)
        },
        async dropdownToggle(){
            document.querySelector(".dropdown-wrapper").classList.toggle("show-wrapper");
        },
        async logout(){
            await this.$store.dispatch('auth_logout')
            this.$root.$emit("logout", true)
            this.$toast.success("Logout success")
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped>
.navbar-dark .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
}
</style>


export const adminTemplate = {
    init: (element, settings) => {
            if (!element) return;
            var myid = element.id;
        
            // General option for vertical header 
            var defaults = {
                Theme: true,
                Layout: 'vertical',
                LogoBg: 'skin1',
                NavbarBg: 'skin6',
                SidebarType: 'full',
                SidebarColor: 'skin1',
                SidebarPosition: false,
                HeaderPosition: false,
                BoxedLayout: false
            };
        
            settings = Object.assign({}, defaults, settings);
        
            var AdminSettings = {
                // Settings INIT
                AdminSettingsInit: function () {
                    this.ManageTheme();
                    this.ManageThemeLayout();
                    this.ManageThemeBackground();
                    this.ManageSidebarType();
                    this.ManageSidebarColor();
                    this.ManageSidebarPosition();
                    this.ManageBoxedLayout();

                    this.handleSidebarMobie();
                },
                // ManageTheme functions
                ManageTheme: function () {
                    var themeview = settings.Theme;
                    switch (settings.Layout) {
                        case 'vertical':
                        case 'horizontal':
                            if (themeview === true) {
                                document.body.setAttribute("data-theme", 'dark');
                                document.getElementById("theme-view").checked = true;
                            } else {
                                element.setAttribute("data-theme", 'light');
                                document.body.checked = false;
                            }
                            break;
                        default:
                    }
                },
                // ManageThemeLayout functions
                ManageThemeLayout: function () {
                    switch (settings.Layout) {
                        case 'horizontal':
                            element.setAttribute("data-layout", "horizontal");
                            break;
                        case 'vertical':
                            element.setAttribute("data-layout", "vertical");
                            // Add any custom scrollbar initialization for vertical layout if needed.
                            break;
                        default:
                    }
                },
                // ManageThemeBackground functions
                ManageThemeBackground: function () {
                    function setlogobg() {
                        var lbg = settings.LogoBg || "skin1";
                        var navbarHeader = document.querySelector(`#${myid} .topbar .top-navbar .navbar-header`);
                        if (navbarHeader) {
                            navbarHeader.setAttribute("data-logobg", lbg);
                        }
                    }
        
                    function setnavbarbg() {
                        var nbg = settings.NavbarBg || "skin1";
                        var navbarElements = document.querySelectorAll(`#${myid} .topbar, #${myid} .topbar .navbar-collapse, #${myid}`);
                        navbarElements.forEach(function (el) {
                            el.setAttribute("data-navbarbg", nbg);
                        });
                    }
        
                    setlogobg();
                    setnavbarbg();
                },
                // ManageSidebarType functions
                ManageSidebarType: function () {
                    function setsidebartype() {
                        var width = window.innerWidth || screen.width;
                        if (width < 1170) {
                            const mainWrapper = document.getElementById("main-wrapper");
                            mainWrapper.classList.add("mini-sidebar");
                            document.getElementById("main-wrapper").setAttribute("data-sidebartype", "mini-sidebar");
                        } else {
                            document.getElementById("main-wrapper").setAttribute("data-sidebartype", settings.SidebarType);
                        }
                    }
        
                    setsidebartype();
                    window.addEventListener("resize", setsidebartype);
                    window.addEventListener("DOMContentLoaded", setsidebartype);
        
                    var toggler = document.querySelector('.sidebartoggler');
                    if (toggler) {
                        toggler.addEventListener("click", function () {
                            var wrapper = document.getElementById("main-wrapper");
                            wrapper.classList.toggle("mini-sidebar");
                            toggler.checked = wrapper.classList.contains("mini-sidebar");
                            wrapper.setAttribute("data-sidebartype", wrapper.classList.contains("mini-sidebar") ? "mini-sidebar" : settings.SidebarType);
                        });
                    }
                },
                // ManageSidebarColor functions
                ManageSidebarColor: function () {
                    function setsidebarbg() {
                        var sbg = settings.SidebarColor || "skin1";
                        var sidebar = document.querySelector(`#${myid} .left-sidebar`);
                        if (sidebar) {
                            sidebar.setAttribute("data-sidebarbg", sbg);
                        }
                    }
                    setsidebarbg();
                },
                // ManageSidebarPosition functions
                ManageSidebarPosition: function () {
                    if (settings.Layout === 'vertical') {
                        var sidebarPosition = settings.SidebarPosition ? 'fixed' : 'absolute';
                        var headerPosition = settings.HeaderPosition ? 'fixed' : 'relative';
                        element.setAttribute("data-sidebar-position", sidebarPosition);
                        element.setAttribute("data-header-position", headerPosition);
        
                        // document.getElementById("sidebar-position").checked = settings.SidebarPosition;
                        // document.getElementById("header-position").checked = settings.HeaderPosition;
                    }
                },
                // ManageBoxedLayout functions
                ManageBoxedLayout: function () {
                    if (settings.Layout === 'vertical' || settings.Layout === 'horizontal') {
                        var boxedlayout = settings.BoxedLayout ? 'boxed' : 'full';
                        element.setAttribute("data-boxed-layout", boxedlayout);
                        // document.getElementById("boxed-layout").checked = settings.BoxedLayout;
                    }
                },

                handleSidebarMobie: function () {
                    try {
                        
                        // Toggle sidebar visibility
                        const navToggler = document.querySelector('.nav-toggler');
                        const mainWrapper = document.querySelector('#main-wrapper');
                        const navTogglerIcon = document.querySelector('.nav-toggler i');
    
                        navToggler.addEventListener('click', function() {
                            mainWrapper.classList.toggle('show-sidebar');
                            navTogglerIcon.classList.toggle('ti-menu');
                        });

                        const topbartoggler = document.querySelector('.topbartoggler');
                        const navbarSupportedContent = document.getElementById('navbarSupportedContent');

                        topbartoggler.addEventListener('click', function() {
                            navbarSupportedContent.classList.toggle('show');
                        });
    
                        // Toggle lock-nav class
                        // const navLock = document.querySelector('.nav-lock');
                        // const body = document.body;
                        // const navLockIcon = document.querySelector('.nav-lock i');
                        // const pageWrapper = document.querySelector('.page-wrapper');
    
                        // navLock.addEventListener('click', function() {
                        // body.classList.toggle('lock-nav');
                        // navLockIcon.classList.toggle('mdi-toggle-switch-off');
                        // const resizeEvent = new Event('resize');
                        // body.dispatchEvent(resizeEvent);
                        // pageWrapper.dispatchEvent(resizeEvent);
                        // });
                    } catch (error) {
                        console.error('handleSidebarMobie is error', error)
                    }

                }
            };
        
            AdminSettings.AdminSettingsInit();
    },
    preloader: () => {
        const preloader = document.querySelector('.preloader');
        if (!preloader) return;
        preloader.style.transition = 'opacity 0.5s'; // Adjust the duration as needed
        preloader.style.opacity = 0;

        setTimeout(function() {
            preloader.style.display = 'none';
        }, 500); // The timeout should match the duration of the transition (0.5s in this case)
    },
    expandLogo: () => {
        const leftSidebar = document.querySelector('.left-sidebar');
        // if (!leftSidebar) return;
        const navbarHeader = document.querySelector('.navbar-header');
        // if (!navbarHeader) return;

        leftSidebar.addEventListener('mouseenter', function() {
        navbarHeader.classList.add('expand-logo');
        });

        leftSidebar.addEventListener('mouseleave', function() {
        navbarHeader.classList.remove('expand-logo');
        });
    },
    handleSidebarMobile: (hide) => {
        const mainWrapper = document.querySelector('#main-wrapper');
        const navTogglerIcon = document.querySelector('.nav-toggler i');

        var width = window.innerWidth || screen.width;
        if (width < 1170) {
            if (hide) {
                mainWrapper.classList.remove('show-sidebar');
                navTogglerIcon.classList.add('ti-menu');
            } else {
                mainWrapper.classList.add('show-sidebar');
                navTogglerIcon.classList.remove('ti-menu');
            }
        }
    }
}
import axios from 'axios';

const state = {
    // user: null,
    // isLoggedIn: false,
};

const getters = {
    // isLoggedIn (state){
    //     return state.isLoggedIn
    // },
    // user (state) {
    //     return state.user
    // }
};

const actions = {
    user_detail() {
        return new Promise((resolve, reject) => { 
            axios.get('user')
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('user', data)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_reset_api_key({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.post('user/reset-api-key')
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_myPackage() {
        return new Promise((resolve, reject) => { 
            axios.get('user/package')
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_statistics({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('user/statistics?date=' + data.date)
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_get_oauth({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('user/google/oauth')
            .then(response => {
                commit("PUSH_PACKAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    user_export({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('admin/users/export')
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    // set_user (state, data) {
    //     state.user = data
    //     state.isLoggedIn = true
    // },
    // set_role (state, data) {
    //     state.role = data
    // },
    // reset_user (state) {
    //     state.user = null
    //     state.isLoggedIn = false
    //     state.role = null
    // }
};

export default {
  state,
  getters,
  actions,
  mutations
};
import axios from 'axios';

const state = {
    incoming: "",
};

const getters = {

};

const actions = {
    incoming_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('incomings', { params: data })
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    incoming_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('incoming/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    incoming_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('incoming/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    incomings_delete_all({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('incomings/delete/all', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_MESSAGE(state, data){
        state.incoming = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
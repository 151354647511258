import axios from 'axios';

const state = {
    contacts: "",
    groups: ""
};

const getters = {

};

const actions = {
    contact_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('contact', data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_create_bulk({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('contact/bulk', data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            let d = {
                name: data.name,
                number: data.number,
                group: null,
                variable: data.variable
            }
            axios.put('contact/' + data.id, d)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('contact/' + data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('contacts', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_blast_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('gateway/contacts/blast_list', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_blast_prepare({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('gateway/contacts/blast_prepare', data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_export({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('contacts/export')
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('contact/' + data)
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_repair({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('contact/fix-broken')
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_empty({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.delete('contact')
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_load_from_device({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post("contacts/load/device", data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_load_group_device({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post("contacts/load/group", data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    contact_load_group_participants({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post("contacts/load/group/participants", data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('group', data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('groups', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_list_dropdown({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('groups/dropdown')
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_update_by_contact({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('contact/' + data.contact_id + "/group", {add: data.add, remove: data.remove})
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_update_by_list_contact({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('contacts/' + data.grup_id + "/list_ke_group", {arrKontak: data.contact_id})
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('group/' + data.id)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('group/' + data)
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_list_member({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('group/' + data.group_id + '/member', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_daftar_member({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('group/' + data.group_id + '/all_member', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_list_not_member({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('contacts/search', { params: {"q": data.search, "group_id": data.group_id} })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_list_all_not_member({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('contacts/getAllNotGrupMember', { params:  data} )
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_member_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('group/' + data.group_id + '/' + data.id)
            .then(response => {
                commit("DELETE_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('group/' + data.id, data)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_move({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('group/' + data.current + '/move?target=' + data.target)
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_member_export({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('group/' + data.group_id + "/export")
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    group_repair({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('gateway/groups/repair', { params: data })
            .then(response => {
                commit("PUSH_CONTACTS", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CONTACTS(state, data){
        state.contacts = data
    },
    DELETE_CONTACTS(state, data){
        state.contacts = data
    },
    PUSH_GROUPS(state, data){
        state.groups = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};

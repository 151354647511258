import axios from 'axios';

// const state = {
//     agency: "",
// };

// const getters = {

// };

const actions = {
    agency_user_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/users', { params: data })
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('agency/user/' + data.id)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_one({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('agency/user/' + data.id)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('agency/user', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('agency/user/' + data.user.id, data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    agency_user_add_balance({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('agency/user/' + data.id + '/balance', data)
            .then(response => {
                commit("PUSH_ADMIN", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_ADMIN(state, data){
        state.agency = data
    },
};


export default {
//   state,
//   getters,
  actions,
  mutations
};
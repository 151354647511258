import axios from 'axios';

const state = {
    outgoing: "",
};

const getters = {

};

const actions = {
    outgoing_send({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoing/send', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('outgoings', { params: data })
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_export({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('outgoings/export')
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_export_with_range_date({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/export', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('outgoing/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_resend({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoing/resend', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('outgoing/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_delete_all({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/delete/all', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_delete_failed({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/delete/failed', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_delete_pending({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/delete/pending', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    outgoing_delete_waiting({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/delete/waiting', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    broadcast_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('blast', { params: data })
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    broadcast_list_jobs({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('blast/jobs', { params: data })
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    broadcast_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('blast/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    broadcast_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('blast/' + data.id)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    broadcast_edit({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('blast/' + data.id, data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    resend_menunggu_jadwal({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/resend/menunggu_jadwal', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    resend_menunggu_device_terkoneksi({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/resend/menunggu_device_terkoneksi', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    resend_gagal({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('outgoings/resend/gagal', data)
            .then(response => {
                commit("PUSH_MESSAGE", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_MESSAGE(state, data){
        state.outgoing = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
<template>
    <aside class="left-sidebar">
        <div class="scroll-sidebar" style="overflow: auto;">
            <!-- <perfect-scrollbar> -->
                <nav class="sidebar-nav">
                    <ul class="sidebarnav">
                        <!-- User Profile-->
                        <template v-if="level == 'user' || level == 'agency' || level == 'user-agency' || level == 'admin'">
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Main Menu</span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark" to="/dashboard">
                                    <i class="nav-icon  fas fa-home"></i>
                                    <span class="menu-text hide-menu">Dashboard</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <a href="https://tutorial.whacenter.id/docs/" target="_blank" class="sidebar-link waves-effect waves-dark sidebar-link" aria-expanded="false">
                                    <i class="nav-icon  fas fa-book-reader"></i>
                                    <span class="hide-menu">Tutorial</span>
                                </a>
                            </li> -->
                            <!-- <li>
                                <a href="" class="">
                                    <i class="nav-icon  fas fa-headphones"></i>
                                    <span class="menu-text hide-menu">Dashboard CS</span>
                                </a>
                            </li> -->
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Data </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark" to="/contact">
                                    <i class="nav-icon  fas fa-address-book"></i>
                                    <span class="menu-text hide-menu">Data Kontak</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/group">
                                    <i class="nav-icon  fas fa-users"></i>
                                    <span class="menu-text hide-menu">Group Kontak</span>
                                </router-link>
                            </li>
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Outgoing </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/send">
                                    <i class="nav-icon  fas fa-paper-plane"></i>
                                    <span class="menu-text hide-menu">Kirim Pesan</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/outgoing">
                                    <i class="nav-icon  fas fa-envelope"></i>
                                    <span class="menu-text hide-menu">List Pesan</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link class="sidebar-link waves-effect waves-dark sidebar-link" to="/broadcast">
                                    <i class="nav-icon  fas fa-reply-all"></i>
                                    <span class="menu-text hide-menu">List Broadcast</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/broadcast">
                                    <i class="nav-icon  fas fa-bullhorn"></i>
                                    <span class="menu-text hide-menu">List Broadcast</span>
                                </router-link>
                            </li> -->
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Incoming </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/incoming">
                                    <i class="nav-icon  fas fa-inbox"></i>
                                    <span class="menu-text hide-menu">List Pesan</span>
                                </router-link>
                            </li>
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Otomasi </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/autoreply">
                                    <i class="nav-icon  fas fa-reply-all"></i>
                                    <span class="menu-text hide-menu">Autoreply</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/google-form-generator">
                                    <i class="nav-icon  fas fa-file-code"></i>
                                    <span class="menu-text hide-menu">Google Form</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/google-spreadsheet-generator">
                                    <i class="nav-icon  fas fa-file-code"></i>
                                    <span class="menu-text hide-menu">Google Spreadsheet</span>
                                </router-link>
                            </li> -->
                            <!-- <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Fitur Premium</span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/web-whatsapp">
                                    <i class="nav-icon  fas fa-comment"></i>
                                    <span class="menu-text hide-menu">Web Whatsapp</span>
                                </router-link>
                            </li> -->
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/customer-service">
                                    <i class="nav-icon  fas fa-headphones-alt"></i>
                                    <span class="menu-text hide-menu">Customer Service</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/chat-widget">
                                    <i class="nav-icon  fas fa-address-card"></i>
                                    <span class="menu-text hide-menu">Chat Widget</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/campaign">
                                    <i class="nav-icon  fas fa-newspaper"></i>
                                    <span class="menu-text hide-menu">Campaign</span>
                                </router-link>
                            </li> -->
                            <!--<li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/order-online">
                                    <i class="nav-icon  fas fa-shopping-cart"></i>
                                    <span class="menu-text hide-menu">OrderOnline.id</span>
                                </router-link>
                            </li>-->
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/link-rotator">
                                    <i class="nav-icon  fas fa-sync"></i>
                                    <span class="menu-text hide-menu">Product Link Rotator</span>
                                </router-link>
                            </li>
                            <!-- <li>
                        <a href=""
                        class="">
                        <i class="nav-icon  fas fa-shopping-bag"></i>
                        <span class="menu-text hide-menu">Form Order</span>
                        </a>
                    </li> -->
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Integrasi </span>
                            </li>
                            <!-- <li>
                                <a href="" class="">
                                    <i class="nav-icon  fas fa-book-reader"></i>
                                    <span class="menu-text hide-menu">Tutorial</span>
                                </a>
                            </li> -->
                            <!-- <li>
                                <a href="" class="">
                                    <i class="nav-icon  fas fa-code-branch"></i>
                                    <span class="menu-text hide-menu">Integrasi</span>
                                </a>
                            </li> -->
                            <!-- <li class="sidebar-item">
                                <a href="https://docs.whacenter.online" target="_blank" class="sidebar-link waves-effect waves-dark sidebar-link" aria-expanded="false">
                                    <i class="nav-icon  fas fa-code"></i>
                                    <span class="menu-text hide-menu">Dokumentasi Rest API</span>
                                </a>
                            </li> -->
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/webhook">
                                    <i class="nav-icon  fas fa-file-import"></i>
                                    <span class="menu-text hide-menu">Webhook</span>
                                </router-link>
                            </li>
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Pengaturan </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/device">
                                    <i class="nav-icon  fas fa-mobile-alt"></i>
                                    <span class="menu-text hide-menu">Device</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/billing">
                                    <i class="nav-icon  fas fa-wallet"></i>
                                    <span class="menu-text hide-menu">Tagihan</span>
                                </router-link>
                            </li> -->
        
                            <div v-if="level == 'agency'">
                                <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                    <span class="hide-menu"> Agency Menu </span>
                                </li>
                                <li class="sidebar-item">
                                    <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/agency/users">
                                        <i class="nav-icon  fas fa-users-cog"></i>
                                        <span class="menu-text hide-menu">User Agency</span>
                                    </router-link>
                                </li>
                            </div>
                        </template>
                        <template v-if="level === 'admin'">
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Admin Menu</span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark" to="/admin/user">
                                    <i class="nav-icon  fas fa-users"></i>
                                    <span class="menu-text hide-menu">Pengguna</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/device-diskonek">
                                    <i class="nav-icon  fas fa-mobile"></i>
                                    <span class="menu-text hide-menu">All Device</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/pengaturan">
                                    <i class="nav-icon  fas fa-cog"></i>
                                    <span class="menu-text hide-menu">Pengaturan</span>
                                </router-link>
                            </li>
                        </template>
        
                        <template v-if="level == 'customer_service'">
                            <!-- <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Main menu </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/dashboard">
                                    <i class="nav-icon  fas fa-home"></i>
                                    <span class="menu-text hide-menu">Dashboard</span>
                                </router-link>
                            </li> -->
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Fitur Premium</span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/web-whatsapp">
                                    <i class="nav-icon  fas fa-comment"></i>
                                    <span class="menu-text hide-menu">Web Whatsapp</span>
                                </router-link>
                            </li>
                            <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu">Data</span>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/order">
                                    <i class="nav-icon  fas fa-cart-shopping"></i>
                                    <span class="menu-text hide-menu">Order</span>
                                </router-link>
                            </li> -->
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/kategori">
                                    <i class="nav-icon  fas fa-list"></i>
                                    <span class="menu-text hide-menu">Kategori</span>
                                </router-link>
                            </li> -->
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/autotext">
                                    <i class="nav-icon  fas fa-book"></i>
                                    <span class="menu-text hide-menu">Autotext</span>
                                </router-link>
                            </li>
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/cs-page/toko">
                                    <i class="nav-icon  fas fa-location-dot"></i>
                                    <span class="menu-text hide-menu">Alamat Toko</span>
                                </router-link>
                            </li> -->
                        </template>
        
                        <!-- <template v-if="level == 'admin'"> -->
                            <!-- <li class="nav-small-cap">
                                <i class="mdi mdi-dots-horizontal"></i>
                                <span class="hide-menu"> Admin Menu </span>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/laporan">
                                    <i class="nav-icon  fas fa-book"></i>
                                    <span class="menu-text hide-menu">Laporan</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/statistics">
                                    <i class="nav-icon  fas fa-desktop"></i>
                                    <span class="menu-text hide-menu">Monitoring</span>
                                </router-link>
                            </li> -->
                            <!-- <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/paket">
                                    <i class="nav-icon  fas fa-wallet"></i>
                                    <span class="menu-text hide-menu">List Paket</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/users">
                                    <i class="nav-icon  fas fa-users-cog"></i>
                                    <span class="menu-text hide-menu">List User</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/message-template">
                                    <i class="nav-icon  fas fa-envelope"></i>
                                    <span class="menu-text hide-menu">Template Pesan</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/messages">
                                    <i class="nav-icon  fas fa-mail-bulk"></i>
                                    <span class="menu-text hide-menu">Message User</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/withdraw">
                                    <i class="nav-icon  fas fa-money-bill"></i>
                                    <span class="menu-text hide-menu">Withdraw</span>
                                </router-link>
                            </li>
                            <li class="sidebar-item">
                                <router-link @click.native="onPageChange" class="sidebar-link waves-effect waves-dark sidebar-link" to="/admin/coupon">
                                    <i class="nav-icon  fas fa-percentage"></i>
                                    <span class="menu-text hide-menu">Coupon</span>
                                </router-link>
                            </li> -->
                        <!-- </template> -->
                    </ul>
                </nav>
            <!-- </perfect-scrollbar> -->
        </div>
    </aside>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                asideShow: true,
                level: localStorage.getItem("role")
            }
        },
        mounted() {
            this.initTemplate();
            // var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
            // if (isMobile) {
            //     document.querySelector(".sidebar").classList.toggle("collapsed")
            // }

            this.$root.$on("toggle_sidebar", () => {
                //$('.overlay-dark-sidebar').toggleClass('show');
                document.querySelector(".sidebar").classList.toggle("collapsed")
                document.querySelector(".contents").classList.toggle("expanded")
            })

            // window.addEventListener('resize', () => {
            //     if (isMobile) {
            //         document.querySelector(".sidebar").classList.toggle("collapsed")
            //     }
            // })

        },
        methods: {
            initTemplate() {
                var element = document.getElementById("main-wrapper");
                console.log('element', element)

                this.$adminTemplate.init(element, {
                    Theme: false, // this can be true or false ( true means dark and false means light ),
                    Layout: 'vertical',
                    LogoBg: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
                    NavbarBg: 'skin6', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
                    SidebarType: 'full', // You can change it full / mini-sidebar / iconbar / overlay
                    SidebarColor: 'skin5', // You can change the Value to be skin1/skin2/skin3/skin4/skin5/skin6
                    SidebarPosition: true, // it can be true / false ( true means Fixed and false means absolute )
                    HeaderPosition: true, // it can be true / false ( true means Fixed and false means absolute )
                    BoxedLayout: false // it can be true / false ( true means Boxed and false means Fluid )
                });

                this.$adminTemplate.preloader();
                this.$adminTemplate.expandLogo();
            },
            getUserName(){

            },
            onPageChange() {
                try {
                    // console.log('check')
                } finally {
                    this.$adminTemplate.handleSidebarMobile(true);
                }
            }
        }
    }
</script>

<style scoped>
    /* .router-link-active * {
        color: #5f63f2 !important;
    } 
    .sidebar-nav > ul > li.selected {
        padding: 0 0 0 10px;
    }
    .sidebar-nav > ul > li.selected > a {
        border-radius: 60px 0 0 60px;
        background: #4798e8;
        color: #fff;
    }    
    */
    .sidebar-item > .router-link-active {
        padding: 12px 15px !important;
        margin-left: 10px;
        border-radius: 60px 0 0 60px;
        background: #4798e8;
        color: #fff;
        opacity: 1 !important;
    }
    .ps {
        height: calc(100% - 20px);
    }
</style>

import axios from 'axios';

const state = {
    toko: "",
};

const getters = {

};

const actions = {
   toko_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/origin', { params: data })
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
   toko_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/origin', data)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
   toko_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/origin/' + data.id)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
   toko_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/origin/' + data.id, data)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
   toko_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('cs-management/origin/' + data.id)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    rajaongkir_province({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/rajaongkir/province')
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    rajaongkir_city({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/rajaongkir/city?id=' + data.id)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    rajaongkir_subdistrict({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/rajaongkir/subdistrict?id=' + data.id)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_TOKO(state, data){
        state.toko = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
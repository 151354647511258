<template>
    <div>
        <a v-if="oldTokenExist" v-on:click="backToAdmin" class="float">
            <i class="fas fa-backward my-float"></i>
        </a>
    </div>
</template>

<style scoped>
    .float {
        cursor: pointer;
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        z-index: 999;
    }

    .my-float {
        margin-top: 22px;
    }
</style>

<script>
    export default {
        components: {},
        data() {
            return {
                oldTokenExist: false
            }
        },
        mounted() {
            if (localStorage.getItem("old_token")) {
                this.oldTokenExist = true
            }
        },
        methods: {
            async backToAdmin() {
                try {
                    await this.$store.dispatch("auth_login_admin_back", {"login": true});
                    await this.$root.$emit("login", true);
                    //this.$toast.success("Login success");
                    await this.$router.push("/admin/users");
                    window.location.reload()
                    // this.$toast.success("Login success");
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            }
        }
    }
</script>
import axios from 'axios';

const state = {
    category: "",
};

const getters = {

};

const actions = {
    category_all({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/category/all', { params: data })
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    category_list({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/category', { params: data })
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    category_create({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/category', data)
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    category_detail({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/category/' + data.id)
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    category_update({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.put('cs-management/category/' + data.id, data)
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
    category_delete({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.delete('cs-management/category/' + data.id)
            .then(response => {
                commit("PUSH_CATEGORY", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_CATEGORY(state, data){
        state.category = data
    },
};


export default {
  state,
  getters,
  actions,
  mutations
};
import axios from 'axios';

const state = {
    toko: "",
   
};

const getters = {

};

const actions = {
   ambil_toko({ commit }) {
        return new Promise((resolve, reject) => { 
            axios.get('cs-management/toko/origin')
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
	ambilOngkir({ commit }, data) {
        return new Promise((resolve, reject) => { 
            axios.post('cs-management/rajaongkir/cost' , data)
            .then(response => {
                commit("PUSH_TOKO", "OK")
                resolve(response)
            })
            .catch(err => {
                reject(err)
          })
        })
    },
}

const mutations = {
    PUSH_TOKO(state, data){
        state.toko = data
    }
};


export default {
  state,
  getters,
  actions,
  mutations
};